<template>
  <div>
    <div v-if="!loaded" class="container Nunito" style="max-width: 100%">
      <section class="section headerSection">
        <div class="container has-text-centered">
          <div>
            <div class="b-skeleton is-centered is-animated">
              <div id="pfp-skel" class="b-skeleton-item is-rounded" style="height: 128px; width: 128px; border-radius: 50%;">
              </div>
            </div>
          </div>
          <div class="b-skeleton is-centered is-animated">
            <div @onclick="changeLevelPodiumClient()" id="leaderTextSkel" class="b-skeleton-item is-rounded" style="height: 36px; width: 30%;margin-bottom: 1.5rem;margin-top:10px;"></div>
          </div>
        </div>
      </section>
      <section class="section" style="padding: 0;">
        <div id="podium-box" class="columns is-centered is-multiline is-narrow" >
          <div class="column is-4 step-container" style="padding-right: 10px !important;">
            <div id="top-pfp">
            <div id="top-pfp-img" class="b-skeleton is-animated">
              <div id="pfp-skel1" class="b-skeleton-item is-rounded" style="height: 128px; width: 128px; border-radius: 50%;">
              </div>
            </div>
            <div id="top-pfp-text" style="display: flex;" class="b-skeleton is-centered is-animated">
              <div class="b-skeleton-item is-rounded" style="width: 65%;height:20px;box-shadow: 0 0.1rem 1.0rem var(--main-color-bg-3); !important;"></div></div>
            </div>
            <div id="second-step" class="step centerBoth podium-number has-shadow Nunito" style="position: relative;">
              <div style="position:absolute;width:100%;bottom:0;text-align:center;" class="Nunito">
                <h1 class="title" style="font-size: 1em;">2</h1>
              </div>
            </div>
          </div>
          <div class="column is-4 step-container" style="padding-right: 10px !important;">
            <div id="top-pfp">
            <div id="top-pfp-img" class="b-skeleton is-animated">
              <div id="pfp-skel1" class="b-skeleton-item is-rounded" style="height: 128px; width: 128px; border-radius: 50%;">
              </div>
              <img src="https://cdn.gracebot.net/grace/crown.png" class="crown" />
            </div>
            <div id="top-pfp-text" style="display: flex;" class="b-skeleton is-centered is-animated">
              <div class="b-skeleton-item is-rounded" style="width: 65%;height:20px;box-shadow: 0 0.1rem 1.0rem var(--main-color-bg-3); !important;"></div></div>
            </div>
            <div id="first-step" class="step centerBoth podium-number has-shadow Nunito" style="position: relative;">
              <div style="position:absolute;width:100%;bottom:0;text-align:center;" class="Nunito">
                <h1 class="title" style="font-size: 1em;">1</h1>
              </div>
            </div>
          </div>
          <div class="column is-4 step-container" style="">
            <div id="top-pfp">
            <div id="top-pfp-img" class="b-skeleton is-animated">
              <div id="pfp-skel1" class="b-skeleton-item is-rounded" style="height: 128px; width: 128px; border-radius: 50%;">
              </div>
            </div>
            <div id="top-pfp-text" style="display: flex;" class="b-skeleton is-centered is-animated">
              <div class="b-skeleton-item is-rounded" style="width: 65%;height:20px;box-shadow: 0 0.1rem 1.0rem var(--main-color-bg-3); !important;"></div></div>
            </div>
            <div id="third-step" class="step centerBoth podium-number has-shadow Nunito" style="position: relative;">
              <div style="position:absolute;width:100%;bottom:0;text-align:center;" class="Nunito">
                <h1 class="title" style="font-size: 1em;">3</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="section" id="secStack">
        <div class="columns is-centered" id="columStack">
          <!-- <div class="column"></div> -->
          <div id="levelCards" class="column is-centered is-three-quarters has-shadow levelrows">
            <div class="columns is-multiline">
              <div class="column is-4 levelUserCard" v-for="(number, index) in [0,1,2,3,4,5,6,7,8]" :key="number" :id="index">
                <div class="card has-shadow userLeader">
                  <div class="card-content">
                    <div class="media">
                      <div class="media-left">
                        <figure class="image">
                          <div class="b-skeleton is-centered is-animated">
                            <div id="userLeader-pfp-skel" class="b-skeleton-item is-rounded" style="height: 80px; width: 80px; border-radius: 50%;">
                            </div>
                          </div>
                        </figure>
                      </div>
                      <div class="media-content">
                        <div class="b-skeleton is-animated">
                          <div id="leaderTextSkel" class="b-skeleton-item is-rounded" style="height: 25px; width: 45%;margin-bottom: 0px;"></div>
                        </div>
                        <div class="b-skeleton is-animated">
                          <div id="leaderTextSkel" class="b-skeleton-item is-rounded" style="height: 15px; width: 28%;margin: 0px;padding:0px;"></div>
                        </div>
                        <div class="b-skeleton is-animated">
                          <div id="leaderTextSkel" class="b-skeleton-item is-rounded" style="height: 15px; width: 40%;margin: 0px;padding:0px;"></div>
                        </div>
                        <div class="b-skeleton is-animated">
                          <div id="leaderTextSkel" class="b-skeleton-item is-rounded" style="height: 15px; width: 35%;margin: 0px;padding:0px;"></div>
                        </div>
                      </div>
                      <div class="media-right">
                        <div class="b-skeleton is-animated">
                          <div id="leaderTextSkel" class="b-skeleton-item is-rounded" style="height: 20px; width: 30px;margin: 0px;"></div>
                        </div>
                      </div>
                    </div>

                    <div class="content" style="flex: auto;">
                      <b-progress size="is-medium" :value="100" :show-value="false">
                      </b-progress>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="column is-2" id="explainInfo" style="padding: 0px;">
            <div class="card has-shadow levelrows has-shadow has-text-white" style="min-height: unset;">
              <div class="card-content">
                <h1 class="title is-4" style="margin-bottom: 10px;">How to rank up</h1>
                <div style="width: 100%;height: 1px;background-color: #474b50;margin-bottom: 15px;"></div>
                <div class="content">
                  Every message you send per one minute, you gain between 10 to 30 XP.
                  <br><br>
                  If your server you can run <code style="background-color: var(--main-color-bg-2);">!level</code> to see your level card, rank, and xp.
                  <br><br>
                  <router-link tag="a" :to="{path: '/lvlcal', query: {premium: false, minXP: 10, maxXP: 30}}">Click Here</router-link> to show the Level Calculator for your server!
                </div>
              </div>
            </div>
            <div class="card has-shadow levelrows has-shadow has-text-white" style="min-height: unset;margin-top: 30px;">
              <div class="card-content">
                <h1 class="title is-4" style="margin-bottom: 10px;">Level Roles</h1>
                <div style="width: 100%;height: 1px;background-color: #474b50;margin-bottom: 15px;"></div>
                <div class="content">
                  <div v-for="(role, index) in [0,1,2,3]" :key="index" class="roleCard">
                    <h1 class="title is-6 has-text-white" style="margin-bottom:5px">Level {{index}}</h1>
                    <p class="has-text-grey-light" :style="`border-color: grey;`" style="border: 1.2px solid; max-width: fit-content; border-radius: 15px;padding: 0px 20px;font-size: 14px;text-align: center;">
                      @Level {{index}}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div v-else class="container Nunito" style="max-width: 100%">
      <section class="section headerSection">
        <div class="container has-text-centered">
          <div v-if="leaderboard.guild.icon">
            <img class="icon-img has-shadow" style="height:128px; width: 128px;" v-if="!leaderboard.guild.icon.includes('a_')" :class="{'static': leaderboard.guild.icon.includes('a_')}" :src="'https://cdn.discordapp.com/icons/' + leaderboard.guild.id + '/' + leaderboard.guild.icon + '.' + ((leaderboard.guild.icon || '').startsWith('a_') ? 'gif' : 'webp') + '?size=128'" :alt="leaderboard.guild.name">
            <img class="icon-img has-shadow" style="height:128px; width: 128px;" v-else :src="'https://cdn.discordapp.com/icons/' + leaderboard.guild.id + '/' + leaderboard.guild.icon + '.gif?size=128'" :alt="leaderboard.guild.name">
          </div>
          <h1 @click="changeLevelPodiumClient()" style="cursor: pointer;" class="title noselect">{{ $store.getters.admin.streamerMode ? '????' : leaderboard.guild.name }}'s Leaderboard</h1>
        </div>
      </section>
      <section class="section" style="padding: 0;">
          <div v-if="!(this.showLevelPodiumClient === false || this.leaderboard.guild.showLevelPodium === 0) && this.board.length > 0 && this.$store.getters.UI === 'new'" id="podium-box" class="columns is-centered is-multiline is-narrow" >
            <div class="column is-4 step-container" v-if="top2" style="padding-right: 10px !important;">
              <div id="top-pfp" >
                <router-link tag="img" :to="{ path: '/profile/' + top2.userid }" title="Click to go to profile" id="top-pfp-img" :src="renderPFP(top2.userid, top2.avatar)" :onerror="'this.src=\'https://cdn.discordapp.com/embed/avatars/' + (leaderboard.guild.id % 5) + '.png?size=128\';'" :alt="(top2.username || 'Unknown').toString()" class="icon-img has-shadow-1"></router-link>
                <h1 id="top-pfp-text" class="subtitle is-3 has-text-white">{{ (top2.username || 'Unknown').toString() }}</h1>
              </div>
              <div id="second-step" class="step centerBoth podium-number has-shadow Nunito" style="position: relative;">
                <div style="position:absolute;width:100%;bottom:0;text-align:center;" class="Nunito">
                  <h1 class="title" style="font-size: 1em;">2</h1>
                </div>
              </div>
            </div>
            <div class="column is-4 step-container" v-if="1" style="padding-right: 10px !important;">
              <div id="top-pfp">
                <div id="top-pfp-img" class="is-inline">
                  <router-link tag="img" :to="{ path: '/profile/' + top1.userid }" title="Click to go to profile" :src="renderPFP(top1.userid, top1.avatar)" :onerror="'this.src=\'https://cdn.discordapp.com/embed/avatars/' + (leaderboard.guild.id % 5) + '.png?size=128\';'" :alt="(top1.username || 'Unknown').toString()" class="icon-img has-shadow-1"></router-link>
                  <img src="https://cdn.gracebot.net/grace/crown.png" class="crown" />
                </div>
                <h1 id="top-pfp-text" class="subtitle is-3 has-text-white">{{ (top1.username || 'Unknown').toString() }}</h1>
              </div>
              <div id="first-step" class="step centerBoth podium-number has-shadow Nunito" style="position: relative;">
                <div style="position:absolute;width:100%;bottom:0;text-align:center;" class="Nunito">
                  <h1 class="title" style="font-size: 1em;">1</h1>
                </div>
              </div>
            </div>
            <div class="column is-4 step-container" v-if="top3" style="">
              <div id="top-pfp" >
                <router-link tag="img" :to="{ path: '/profile/' + top3.userid }" title="Click to go to profile" id="top-pfp-img" :src="renderPFP(top3.userid, top3.avatar)" :onerror="'this.src=\'https://cdn.discordapp.com/embed/avatars/' + (leaderboard.guild.id % 5) + '.png?size=128\';'" :alt="(top3.username || 'Unknown').toString()" class="icon-img has-shadow-1"></router-link>
                <h1 id="top-pfp-text" class="subtitle is-3 has-text-white">{{ (top3.username || 'Unknown').toString() }}</h1>
              </div>
              <div id="third-step" class="step centerBoth podium-number has-shadow Nunito" style="position: relative;">
                <div style="position:absolute;width:100%;bottom:0;text-align:center;" class="Nunito">
                  <h1 class="title" style="font-size: 1em;">3</h1>
                </div>
              </div>
            </div>
          </div>
          <div v-else-if="!(this.showLevelPodiumClient === false || this.leaderboard.guild.showLevelPodium === 0 || (!this.top1 && !this.top2 && !this.top3) === true)" id="podium-box" class="columns is-centered">
            <div class="column is-one-quarter step-container" style="padding-right: 10px !important;">
              <div v-if="top2 && top2.username">
                <h1 class="title Nunito" id="">{{ $store.getters.admin.streamerMode ? '????' : top2.username }}</h1>
              </div>
              <div v-else>
                <h1 class="title">Unknown</h1>
              </div>
              <div id="second-step" class="step centerBoth podium-number has-shadow Nunito">
                2
              </div>
            </div>
            <div class="column is-one-quarter step-container" style="padding-right: 10px !important;">
              <div v-if="top1 && top1.username">
                <h1 class="title Nunito" id="">{{ $store.getters.admin.streamerMode ? '????' : top1.username }}</h1>
              </div>
              <div v-else>
                <h1 class="title">Unknown</h1>
              </div>
              <div id="first-step" class="step centerBoth podium-number has-shadow Nunito">
                1
              </div>
            </div>
            <div class="column is-one-quarter step-container" style="">
              <div v-if="top3 && top3.username">
                <h1 class="title Nunito" id="">{{ $store.getters.admin.streamerMode ? '????' : top3.username }}</h1>
              </div>
              <div v-else>
                <h1 class="title">Unknown</h1>
              </div>
              <div id="third-step" class="step centerBoth podium-number has-shadow Nunito">
                3
              </div>
            </div>
          </div>
      </section>
      <section class="section" id="secStack">
        <div class="columns is-centered" id="columStack">
          <!-- <div class="column"></div> -->
          <div id="levelCards" class="column is-centered is-three-quarters has-shadow levelrows">
            <h1 v-if="this.board.length <= 0" class="title is-1" style="text-align: center;">Start typing to start showing levels!</h1>
            <h1 v-if="this.leaderboard.guild.xpSyncStatus === true" class="title is-2" style="text-align: center;">Transfering Mee6 Leveling!</h1>
            <h1 v-if="this.leaderboard.guild.xpSyncStatus === true" class="subtitle is-4 has-text-white" style="text-align: center;">Please, try again later!</h1>
            <img v-if="this.leaderboard.guild.xpSyncStatus === true" src='https://cdn.gracebot.net/grace/transferData.svg' style="height: 250px;padding-bottom: 10px;display: block;margin-left: auto;margin-right: auto;">
            <div v-else class="columns is-multiline">
              <div class="column is-4 levelUserCard"  v-for="user in this.board" :key="user.userid" :id="user.userid">
                <div class="card has-shadow userLeader">
                  <div class="card-content">
                    <button v-if="(userAcct ? (userAcct.guild ? userAcct.guild.isManager : false) : false)" @click="xpUserReset($event, user)" class="delete delete-button"></button>
                    <div class="media">
                      <div class="media-left">
                        <figure class="image">
                          <router-link tag="img" :to="{ path: '/profile/' + user.userid }" class="has-shadow userLeader-pfp" :src="renderPFP(user.userid, user.avatar)" :onerror="'this.src=\'https://cdn.discordapp.com/embed/avatars/' + (leaderboard.guild.id % 5) + '.png?size=128\';'" :alt="user.username" />
                        </figure>
                      </div>
                      <div class="media-content">
                        <router-link tag="a" :to="{ path: '/profile/' + user.userid }" class="title is-4" style="margin-bottom: 0px;">{{ $store.getters.admin.streamerMode ? '????' : (user.username ? user.username : 'Unknown') }}</router-link>
                        <p class="is-6 has-text-grey-light">Level: {{ user.level }}</p>
                        <p class="is-6 has-text-grey-light">Total Gained Xp: {{ abbrNumber(user.xpTotal) }}</p>
                        <router-link tag="a" :to="{path: '/lvlcal', query: {xp: user.xp.toString(), level: (user.level + 1), premium: (leaderboard.guild.premium ? true : (user.premium > 0 ? true : false)), minXP: leaderboard.xp_per_message[0], maxXP: leaderboard.xp_per_message[1]}}" class="is-6 has-text-grey-light">Xp Calculator</router-link>
                      </div>
                      <div class="media-right">
                        <h1 v-if="(top1 ? top1.userid : '') === user.userid" class="title is-2 mainTextColor">#1</h1>
                        <h1 v-else-if="(top2 ? top2.userid : '') === user.userid" class="title is-3" style="color: grey;">#2</h1>
                        <h1 v-else-if="(top3 ? top3.userid : '') === user.userid" class="title is-4" style="color: #cd7f32;">#3</h1>
                        <h1 v-else class="title is-5">#{{ user.leaderBoardCount.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') }}</h1>
                      </div>
                    </div>

                    <div class="content" style="flex: auto;">
                      <b-progress size="is-medium" :value="user.percentage" show-value>
                        {{ user.percentage.toFixed(1) }}% ({{ user.xp.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') }} / {{ abbrNumber(user.level * 500) }})
                      </b-progress>
                      <div v-if="user.haveLevelRoles.length > 0 && leaderboard.guild.levelRoleUp.length > 0">
                        <div class="columns is-multiline is-centered" v-for="(chunkLevel, index) in chunkArray2(leaderboard.guild.levelRoleUp.filter(f => user.haveLevelRoles.includes(f.roleID)), 2)" :key="index">
                          <div class="column is-centered" v-for="level in chunkLevel" :key="level.id" :style="`visibility: ${level.sameas1 ? 'hidden': 'visible'};`" style="width: max-content; padding-bottom: 0px;">
                            <p v-if="level.discord" class="has-text-grey-light" :style="`border-color: #${level.discord.color.toString(16).padStart(6, '0')};`" style="border: 1.2px solid; width:100%; border-radius: 15px;padding: 0px 20px;font-size: 14px;text-align: center;">
                              {{ level.discord.name }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <h1 v-if="this.loadingMoreInfo && !this.hideLoading" class="title is-3" style="text-align: center;">Loading...</h1>
          </div>
          <div class="column is-2" id="explainInfo" style="padding: 0px;">
            <div class="card has-shadow levelrows has-shadow has-text-white" style="min-height: unset;">
              <div class="card-content">
                <h1 class="title is-4" style="margin-bottom: 10px;">How to rank up</h1>
                <div style="width: 100%;height: 1px;background-color: #474b50;margin-bottom: 15px;"></div>
                <div class="content">
                  Every message you send per one minute, you gain between {{leaderboard.xp_per_message[0]}} to {{leaderboard.xp_per_message[1]}} XP.
                  <br><br>
                  If your server you can run <code style="background-color: var(--main-color-bg-2);">{{leaderboard.guild.prefix}}level</code> to see your level card, rank, and xp.
                  <br><br>
                  <router-link tag="a" :to="{path: '/lvlcal', query: {premium: leaderboard.guild.premium ? true : false, minXP: leaderboard.xp_per_message[0], maxXP: leaderboard.xp_per_message[1]}}">Click Here</router-link> to show the Level Calculator for your server!
                </div>
              </div>
            </div>
            <div v-if="leaderboard.guild.levelRoleUp.length > 0" class="card has-shadow levelrows has-shadow has-text-white" style="min-height: unset;margin-top: 30px;">
              <div class="card-content">
                <h1 class="title is-4" style="margin-bottom: 10px;">Level Roles</h1>
                <div style="width: 100%;height: 1px;background-color: #474b50;margin-bottom: 15px;"></div>
                <div class="content">
                  <div v-for="(role, index) in leaderboard.guild.levelRoleUp" :key="index" class="roleCard">
                    <h1 class="title is-6 has-text-white" style="margin-bottom:5px">Level {{ role.level }}</h1>
                    <p v-if="role.discord" class="has-text-grey-light" :style="`border-color: #${role.discord.color.toString(16).padStart(6, '0')};`" style="border: 1.2px solid; max-width: fit-content; border-radius: 15px;padding: 0px 20px;font-size: 14px;text-align: center;">
                      {{ role.discord.name }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'leaderboard',
    components: {},
    data () {
      return {
        loaded: false,
        leaderboard: null,
        userAcct: null,
        top1: null,
        top2: null,
        top3: null,
        board: [],
        loadingMoreInfo: true,
        hideLoading: false,
        showLevelPodiumClient: true,
        abbrNumber: n => {
          if (n < 1e3) return n
          if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + 'K'
          if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + 'M'
          if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + 'B'
          if (n >= 1e12) return +(n / 1e12).toFixed(1) + 'T'
        },
        chunkArray: function (myArray, chunksize) {
          var index = 0
          var arrayLength = myArray.length
          var tempArray = []
          for (index = 0; index < arrayLength; index += chunksize) {
            const myChunk = myArray.slice(index, index + chunksize)
            tempArray.push(myChunk)
          }
          return tempArray
        },
        chunkArray2: function (myArray, chunksize) {
          var index = 0
          var arrayLength = myArray.length
          var tempArray = []
          for (index = 0; index < arrayLength; index += chunksize) {
            let myChunk = myArray.slice(index, index + chunksize)
            if (myChunk.length === 1) {
              const extra = { ...myArray.slice(index, index + chunksize)[0], sameas1: true }
              myChunk = [...myArray.slice(index, index + chunksize), extra]
            }
            tempArray.push(myChunk)
          }
          return tempArray
        }

      }
    },
    watch: {},
    async beforeDestroy () {
      window.onscroll = undefined
    },
    async created () {
      this.start()
      window.onscroll = () => {
        if (!this.loaded || this.loadingMoreInfo || this.board.length < 50) return
        if (document.documentElement.scrollTop + window.innerHeight === (document.documentElement.scrollHeight)) {
          this.loadingMoreInfo = true
          const Page = this.leaderboard.currentPage
          this.$store.dispatch('getApi', { path: `plugin/level/${this.$route.params.id}`, params: { page: (Page + 1), type: 'guild', filter: 'highest' } }).then(async (result) => {
            this.leaderboard = result.data
            if (result.data.result.length <= 0) {
              this.hideLoading = true
              this.leaderboard.currentPage = Page
              this.$buefy.snackbar.open({
                message: 'No more levels found!',
                type: 'is-warning',
                duration: 15000
              })
              window.onscroll = undefined
            } else {
              for (let i = 0; i < result.data.result.length; i++) {
                this.board.push({ ...result.data.result[i], leaderBoardCount: (this.board.length + 1) })
              }
              this.loadingMoreInfo = false
            }
          }).catch((error) => {
            console.log(error, 'error')
            if (Number(error.response.status) === 404) {
              this.$buefy.snackbar.open({
                message: 'The server/guild your trying to load is not found!',
                type: 'is-warning',
                duration: 15000
              })
              this.loadingMoreInfo = false
            } else if (Number(error.response.status) === 500) {
              this.$buefy.snackbar.open({
                message: 'An 500 error happened when trying to load more data!<br>Check console!',
                type: 'is-danger',
                duration: 15000
              })
              this.loadingMoreInfo = false
            } else {
              this.$buefy.snackbar.open({
                message: 'An unknown error happened!<br>Check console!',
                type: 'is-danger',
                duration: 15000
              })
              this.loadingMoreInfo = false
            }
          })
        }
      }
    },
    methods: {
      start () {
        this.$store.dispatch('getApi', { path: `plugin/level/${this.$route.params.id}`, params: { page: ((!this.$route.query.page || isNaN(this.$route.query.page)) ? Number(0) : Number(this.$route.query.page)), type: 'guild', filter: 'highest' } }).then(async (result) => {
          this.leaderboard = result.data
          this.userAcct = result.data.user
          // this.board.push(...result.data.result)
          for (let i = 0; i < result.data.result.length; i++) {
            this.board.push({ ...result.data.result[i], leaderBoardCount: (this.board.length + 1) })
          }
          if (this.leaderboard.currentPage === 0) {
            if (this.leaderboard.result[0]) this.top1 = this.leaderboard.result[0]
            if (this.leaderboard.result[1]) this.top2 = this.leaderboard.result[1]
            if (this.leaderboard.result[2]) this.top3 = this.leaderboard.result[2]
          }
          this.loaded = true
          this.loadingMoreInfo = false
          const scroll = this.$route.query.scroll
          const jquery = this.$
          await this.$store.dispatch('sleep', 3000)
          if (scroll) {
            if (jquery('#' + scroll).length > 0) {
              jquery('html, body').animate({
                scrollTop: jquery('#' + scroll).offset().top - 100
              }, 2000, function () {
                jquery('#' + scroll).fadeOut().fadeIn('slow')
                try {
                  jquery('#collapse' + scroll).collapse('toggle')
                } catch (d) {
                }
              }).catch(function () {
                alert('$.get failed!')
              })
            }
          }
        }).catch((error) => {
          console.log(error, 'error')
          if (Number(error.response.status) === 404) return this.$router.push('/404?reason=Guild not found')
          if (Number(error.response.status) === 500) return this.$router.push('/500?reason=' + error)
          return this.$router.push('/404?reason=unknown')
        })
      },
      changeLevelPodiumClient () {
        this.showLevelPodiumClient = !this.showLevelPodiumClient
      },
      renderPFP (id, avatar) {
        if (!id || !avatar) return `https://cdn.discordapp.com/embed/avatars/${(this.leaderboard.guild.id % 5)}.png?size=128`
        return `https://cdn.discordapp.com/avatars/${id}/${avatar}.${((avatar || '').startsWith('a_') ? 'gif' : 'webp')}?size=128`
      },
      xpUserReset (el, user) {
        var _this = this
        el = this.$(this.$(el.target)[0].parentElement.parentElement.parentElement)
        el.addClass('is-disabled', true)
        this.$swal.fire({
          // confirmButtonText: 'Next &rarr;',
          showCancelButton: true,
          title: 'Are you sure?',
          text: 'You won\'t be able to undo this!',
          footer: `Pressing will delete ${user.username || 'Unknown'}!`,
          icon: 'warning',
          showLoaderOnConfirm: true,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No'
        }).then((result) => {
          if (result.value) {
            const snackbar = this.$buefy.snackbar.open({
              message: 'Deleteing Member, please wait...',
              type: 'is-warning',
              position: 'is-bottom-right',
              indefinite: true,
              actionText: null
            })
            this.$store.dispatch('deleteApi', { path: `guild/${this.$route.params.id}/levelMember/${user.userid}/`, body: { id: user.userid /* Just incase put id in body if we choose to change it we can */ }, headers: {}, params: {} }).then(async (result) => {
              snackbar.close()
              this.$buefy.snackbar.open({
                message: 'Level Member delete!',
                type: 'is-success',
                position: 'is-bottom-right'
              })
              el.removeClass('is-disabled', true)
              await this.$store.dispatch('sleep', 2000)
              this.loaded = this.leaderboard = this.userAcct = this.top1 = this.top2 = this.top3 = null
              this.board = []
              this.start()
            }).catch(async (error) => {
              console.log(error, 'Error')
              snackbar.close()
              this.$buefy.snackbar.open({
                message: 'Oh no! Some error happened! Try Reloading',
                type: 'is-danger',
                position: 'is-bottom-right'
              })
              el.removeClass('is-disabled', true)
              await this.$store.dispatch('sleep', 2000)
              this.loaded = this.leaderboard = this.userAcct = this.top1 = this.top2 = this.top3 = null
              this.board = []
              this.start()
            })
          } else {
            el.removeClass('is-disabled', true)
          }
        })
      }
    }
  }
</script>

<style>
  .progress::-webkit-progress-bar {
    background: var(--main-color-bg-3) !important;
    box-shadow: 0 0.1rem 1.1rem rgba(0,0,0,.3) !important;
  }
  .progress::-webkit-progress-value {
    background: var(--is-premium-1);
  }
  .carousel .carousel-indicator.is-inside.is-bottom {
    bottom: unset !important;
  }
</style>

<style scoped>

.delete-button {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: 5px;
    margin-top: 5px;
    background-color: rgba(10, 10, 10, 0.4);
    visibility: hidden;
  }

  .delete:hover::before, .delete:hover::after {
    background-color: #f04747 !important;
  }

  .card:hover .delete-button {
    visibility: visible !important;
  }

  @media only screen and (max-width: 768px) {
    .card:hover .delete-button {
      visibility: visible !important;
    }
  }

  a {
    transition: .2s;
  }

  a {
    color: lightgray !important;
  }

  a:hover {
    color: #7957d5 !important;
  }

  @media screen and (max-width: 1023px) {
    .levelUserCard {
      min-width: 100% !important;
    }
  }

  @media screen and (max-width: 1400px) {
    .levelUserCard {
      min-width: 50% !important;
    }
  }

  section {
    height: 100% !important;
  }

  .card-content {
    height: 100%;
  }

  .roleCard:not(:last-child) {
    padding-bottom: 10px;
  }

  .levelrows {
    border-radius: 10px;
    background: var(--main-color-bg-2);
    min-height: 200px;
  }

  .userLeader {
    height: 100%;
    border-radius: 10px;
    background: var(--main-color-bg-2);
  }

  .userLeader-pfp, .userLeader-pfp-skel {
    width: 80px;
    border-radius: 100px;
    border-color: var(--main-color-bg-3);
    border-width: 4px;
    border-radius: 100px;
    border-style: solid;
    cursor: pointer;
    transition: .3s;
  }

  .userLeader-pfp:hover, .userLeader-pfp-skel:hover {
    filter: brightness(.6);
    -webkit-filter: brightness(.6);
    transform: scale(1.02);
    transition: all .3s ease-in-out;
  }

  #top-pfp {
    cursor: pointer;
    position: relative;
    padding-right: 125px;
    padding-bottom: 94px;
    z-index: 1;
  }

  #top-pfp-img {
    position: absolute;
    /* display: inline; */
    width: 128px;
    transition: .3s;
    width: 128px !important;
    height: 128px !important;
  }

  #top-pfp-img:hover {
    filter: brightness(.6);
    -webkit-filter: brightness(.6);
    transform: scale(1.02);
    transition: all .3s ease-in-out;
  }

  #top-pfp-text {
    position: absolute;
    z-index:10;
    width:100%;
    bottom:0;
    text-align:center;
    margin-bottom: -73px;
  }

  @media only screen and (max-width: 768px) {
    /* #podium-box {
      display: list-item !important;
    }
    .step-container {
      display: list-item !important;
    } */
    #top-pfp-text {
      margin-bottom: -110px;
    }
    .crown {
      top: -50px !important;
      transform: scale(1) rotate(-27deg) !important;
      left: -16px !important;
      animation: pulseCrownMobile 2s infinite !important;
    }
    #secStack {
      padding-bottom: 0px;
    }
    #columStack {
      /* display: flex; */
      flex-direction: column-reverse;
    }
    #explainInfo {
      margin-bottom: 30px;
    }
    #columStack {
      display: grid;
    }
    #levelCards {
      order: 2
    }
  }

  @media screen and (min-width: 769px), print {
    #explainInfo {
      margin-left: 2.5%;
      margin-right: 10px;
    }
    #levelCards {
      margin-left: 6%;
    }
  }
  .has-shadow, #pfp-skel, #leaderTextSkel, #userLeader-pfp-skel {
    box-shadow: 0 0.1rem 1.5rem rgba(0,0,0,.3) !important;
  }

  .has-shadow-1, #pfp-skel1 {
    box-shadow: 0 0.8rem 1.5rem rgba(0,0,0,.3) !important;
  }

  .icon-img, #pfp-skel, #pfp-skel1 {
    /* float: left; */
    border-radius: 100px;
    border-color: var(--main-color-bg-3);
    border-width: 4px;
    border-radius: 100px;
    border-style: solid;
    background-color: var(--main-color-bg) !important;
    height:128px;
    width:128px;
  }
  .headerSection {
    width: 100%;
    height: 260px;
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 30px;
    position: relative;
  }
  /* .customcolumn {
    border-radius: 1px;
    border-color: transparent;
    border-width: 1px;
    border-radius: 10px;
    border-style: solid;
    box-shadow: 0px 0px 3px rgba(0,0,0,0.3);
    margin-bottom: 10px;
    cursor: pointer;
    transition: .3s;
  } */

  .levelSep {
    width: 100%;
    height: 1px;
    margin: 10px 0 10px 0;
    background-color: var(--main-color-bg) !important;
  }

  #podium-box {
    padding-top: 20px;
    margin: 0 auto;
    display: flex;
  }

  .podium-number {
    font-weight: bold;
    font-size: 4em;
    color: white;
  }

  .step-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    max-width: 250px !important;
  }

  .crown {
    position: absolute;
    top: -55px;
    left: 66px;
    z-index: 2;
    transform: scale(1) rotate(29deg);
    width: 80px;
    animation: pulseCrown 2s infinite;
  }

  @keyframes pulseCrown {
    0% {
      transform: rotate(29deg) scale(0.95);
    }

    70% {
      transform: rotate(29deg) scale(1);
    }

    100% {
      transform: rotate(29deg) scale(0.95);
    }
  }

  @keyframes pulseCrownMobile {
    0% {
      transform: rotate(-27deg) scale(0.95);
    }

    70% {
      transform: rotate(-27deg) scale(1);
    }

    100% {
      transform: rotate(-27deg) scale(0.95);
    }
  }

  .step-container>div:first-child {
    margin-top: auto;
    text-align: center;
  }

  .step {
    text-align: center;
  }

  #first-step {
    height: 250px;
    background: var(--is-premium-1) /*-webkit-linear-gradient(258deg,#9656eb,#9656eb,#d64bea,#d64bea)*/ !important;
    border-radius: 10px;
  }

  #second-step {
    height: 200px;
    background: grey;
    border-radius: 10px;
  }

  #third-step {
    height: 175px;
    background: #cd7f32;
    border-radius: 10px;
  }

  #textTopTitleUser {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>
